/*
 * @Author: showtan
 * @Date: 2024-07-02 09:18:48
 * @Version: 1.0
 * @LastEditTime: 2024-11-05 10:16:16
 * @LastEditors: showtan
 * @Description: config
 * @FilePath: /japan-h5/src/config/index.js
 */
/** 环境 */
export const dev = true;

/** 域名 */
export const domain = dev ? "https://test.api.taotaoshan.com" : "https://api.taotaoshan.com";

/** 应用程序接口地址 */
export const host = `${domain}/adminapi/`;

/** AES key */
export const aesKey = "JapanTao";

/** RSA */
export const rsaCert = {
	privateKey: `-----BEGIN ENCRYPTED PRIVATE KEY-----
  MIIE6TAbBgkqhkiG9w0BBQMwDgQI3hCgkzWaPyMCAggABIIEyKC1IzPOpMDW/oLF
  l/BMIrtpV9YLevw9u+speUlGf5dBtWpPVlcXj7m3VrQdwjuU48TIzx2/VA2fHVPe
  qv/1st6sWBfKgPx9tRpyPOapbEKGJ5BesdMKkvRq0k07TuS3JjTkaYEEs8vzh8d2
  4dmwcHdzZvcS6HzRcZeQZw/cgJ0W9BxQCb26mV49XaX6PWZP4m7sa6EayiWPLAO4
  S0lUDiK9BsqFqL4t2cdPY+lrOQTUXSnRLDdtQPzGT9ms6kSVtmOmhxIf1SZgeXlv
  En2FtbVp9cDTC3AU10G8o9AJ3rgBb72pEgECoTOYZcx8s6xSPQ/kh249PZCK3jOY
  aIzSqeZBu486stVyEn+507bEw86RzTTGqjMP3vSFYv5VDSDu6dzUyivB61zGBfCa
  LKfkxaO264BO4/g0cI2oZ6ptJjoplp0Lm9KiaLvGugbB9cM+aA2wVaUNF4T3c574
  LWVIyM9UxXpcN63D1pPYnbufqx/ICi1XeZK6qCAK1hqTd7z13fFR6zrKk32nDaYz
  Tz1Rsho20ZzN33Dtt87s5YRF5OxtlKmlCIbS+dF/D1TxgaQnp/1aNAajpxKgdepk
  oW4+PYIRlRco90Tg01WOjlXXa5ApllfAWcj/07yHTuAI/dtkBI/k1a63sucAA2Ha
  q6+vgO/58FxpwrYZ+sIg/lpDDZeu6c966dfRrihwBAWm8tOGRQUx/Bipb+qllf7U
  1N/S2sZLAdK0/a9H34FVXwWskxtZiMgpnqrMzfFiJZYkdwj4q2o8r516swNEBqww
  2gNPLExaX683+tEGNz88ynCaY1w8B+leE8ZrI2TWwgqy49wDonSWqPGlPQsVmBdL
  a6CzfA4xWIvs1JhQDCorSf6nK+bl4/gCCkqOpVWMTlBhpmdTbSat1orzvvWcauhx
  5iEg4mO/URoEl5zgMmzk+fgiNxcZ1AZZ2GdlqtYYZt5reYSezKVs/1rNHM14TKzF
  ppkYtbdumhTwA9TSkcL97K+l7vGtk3imcMjEfGrna1uHVqwqkRREN5juzyXF6tIf
  A2tSSq+TrNmUdexhhI+k3yKURbOoEqcFJ2c5mYPo56KvDyyq/Il5AYl5xRblp/YB
  KHwL11qMNttmrRQPujCeSjoCS5dX/v2m+XrfP/fwqXVvZ6L9YUMEhB1IjrRQ8PRS
  kqtmQBjEjU1sEs0o+w/V1aIrGnfKNEwfO7YX/lPJrJAn6ImXvuvGk9QcUcfCo4SK
  yFrvLWDQkKmAhVUzFhM4PGYG8E/NxrLpjagKoGsVMXwhJmJ24lHGpsXnABjDBMUq
  RdbJIqofFtSn2Aied45Pkiz0ON2erJ3CzfHn3jmqFnOXgU5WVjxJeSxgSpTHFbTS
  fX/XNfziSscpT3zOg0t157svmH9hTBJZMfk1T4IGxPEJStQZDj9J/g9tcImgyTtV
  6Pe5MjOLv46Vy6rDDO41l8hEhsdoW6R8c6emGvynHEILMIcUiCETg421TN5aWAin
  c6/c3RGhM/yEivrwCNW4EStF8qrHRftw5zMiFy8rKdMOuKiYpji+tDlKT07/VadU
  NQiIGllLhUo2ZUiOJC3eAeUcF3zTXcfSM4CBHwGdbgMfRn7V80afNvqw9a+heB61
  AzntYf4GaXQpdIhOLQ==
  -----END ENCRYPTED PRIVATE KEY-----`,
	publicKey: `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCdBn7CC91o81yI7rDk62syzU5R
  Xv915TEh890FDIZb240iHJypluXBt4aMDmkliqRGLlQYllDn1hiw98KSY+A9UORt
  E0FJB4uTTvLEgdaK/2X7gWnEfZ+EY1h1JhckUirK8dC3l3dDRc4FTl3eakJzV5Mf
  LhZUoZYEYbhLtFdQIQIDAQAB
  -----END PUBLIC KEY-----`,
};

/** 首页底部导航标签 */
export const tabs = [
	{
		key: "/",
		status: 1,
		title: "待入库",
		badge: "",
		detail: "/waitingDetail",
	},
	{
		key: "/photograph",
		status: 2,
		title: "入库后拍照",
		badge: "",
		detail: "/photographDetail",
	},
	{
		key: "/packaging",
		status: 3,
		title: "合箱待审",
		badge: "",
		detail: "/packagingDetail",
	},
	{
		key: "/out",
		status: 4,
		title: "出库待审",
		badge: "",
		detail: "/outDetail",
	},
];
